import { getList, solutionDocSubmit, solutionDocDel, OnOrOffShelf } from "@lib";
export default {
  data() {
    return {
      tableData: [],
      page: 1,
    };
  },
  methods: {
    // 删除
    del(val) {
      this.$confirm("确定删除信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await solutionDocDel({ id: val.id });
          if (res.status === "success") {
            this.$message.success("删除成功");
            this.getListFn();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch(() => {});
    },
    // 浏览账户
    look(val) {
      this.$router.push({ name: "solutionDetail", params: { id: val.id } });
    },
    // 新增账户
    addAccount(val) {
      if (val) {
        this.$confirm(
          "该产品解决方案已上架，修改后需重新审核，确定继续修改？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$router.push({ name: "solutionAdd", params: { id: val.id } });
          })
          .catch(() => {});
      } else {
        this.$router.push({ name: "solutionAdd" });
      }
    },
    async shengheFn(val) {
      let res = await solutionDocSubmit({ id: val.id });
      if (res.status === "success") {
        this.$message.success("提交成功");
        this.getListFn();
      } else {
        this.$message.error("提交失败");
      }
    },

    getListFn() {
      this.getListFuction();
    },
    pageChange(currentPage) {
      sessionStorage.setItem("soluPage", currentPage);
      // console.log(currentPage);
      this.page = currentPage;
      this.getListFuction();
    },

    async getListFuction() {
      let { result } = await getList({
        pageNumber: this.page,
        pageSize: 10,
      });
      this.tableData = result;
    },
    async onFn(val, onVal) {
      let res = await OnOrOffShelf({
        id: val.id,
        publishState: onVal,
      });
      if (res.status === "success") {
        this.$message.success("操作成功");
        this.getListFn();
      } else {
        this.$message.error(res?.result?.error_msg || "操作失败");
      }
    },
  },
  mounted() {
    this.getListFn();
  },
  created() {
    let solPage = sessionStorage.getItem("soluPage");
    if (solPage) {
      this.page = Number(solPage);
    }
  },
  beforeRouteEnter: (to, from, next) => {
    if (
      from.name != "solutionDetail" &&
      from.name != "solutionAdd" &&
      from.name != null
    ) {
      sessionStorage.removeItem("soluPage");
    }
    next();
  },
};
